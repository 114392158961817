import { Route, Routes, useLocation } from "react-router-dom";
import './styles.scss';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { connect } from 'react-redux';
import Eventos from './pages/Eventos';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProductoresYSalas from './pages/ProductoresYSalas';
import EventoSeleccionado from './pages/EventoSeleccionado';
import PagoFinalizado from './pages/PagoFinalizado';
import ErrorHandler from './components/ErrorHandler';
import CompraRevalidarMercadoPago from './pages/CompraRevalidarMercadoPago';
import Health from '../desktop/pages/Health';
import CookiePolicy from "./components/cookiePolicy";
import CompraRevalidarRedSys from "./pages/CompraRevalidarRedSys";
import LandingRecinto from "./pages/LandingRecinto";
import { useEffect, useState } from "react";
import VenderEntradas from "./pages/VenderEntradas";
import EventosPopulares from "./pages/EventosPopulares";
import SmartTicketConfirmaTransferencia from "./pages/SmarTicketConfirmaTransferencia";
import TerminarCompraVendedor from "./pages/TerminarCompraVendedor";
import SmartTicket from "./pages/SmartTicket";
import DeudaSeleccionada from "./pages/DeudaSeleccionada";
import PagoValidar from "./pages/PagoValidar";
import EventosImportantes from "./pages/EventosImportantes";

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6600',
    },
    secondary: {
      main: '#ffffff',
    },
  },
});

function App() {
  const [newUrlCartelera, setNewUrlCartelera] = useState('');
  const location = useLocation();

  useEffect(() => {
    setNewUrlCartelera('');
  }, [location]);
  
  return (
    <div className="App App_desktop">
      <ThemeProvider theme={theme}>
        <Header urlCartelera={newUrlCartelera}/>
        <div className='main-wrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="/actuator/health/readiness" element={<Health />}/>
            <Route path='/eventos' element={<Eventos />} />
            <Route path='/productores-y-salas' element={<ProductoresYSalas />} />
            <Route path='/evento/:hashEvent/step/:pasoCompra' element={<EventoSeleccionado />} />
            <Route path='/pagos/:hashEvent/step/:pasoCompra' element={<DeudaSeleccionada />} />
            <Route path='/pago' element={<PagoValidar />} />
            <Route path='/pago-finalizado/:checkoutType/:additionalCode?' element={<PagoFinalizado />} />
            <Route path='/pago-pendiente/:checkoutType/:paymentMethod?/:additionalCode?/:eventType?' element={<PagoFinalizado />} />
            <Route path='/compra-revalidar-mercadopago' element={<CompraRevalidarMercadoPago />} />
            <Route path='/compra-revalidar-redsys' element={<CompraRevalidarRedSys />} />
            <Route path='/vender-entradas' element={<VenderEntradas />} />
            <Route path='/los-30-eventos-mas-populares' element={<EventosPopulares />} />
            <Route path='/25-eventos-importantes-en-espana-2025' element={<EventosImportantes />} />
            <Route path='/confirmar-transferencia' element={<SmartTicketConfirmaTransferencia />} />
            <Route path='/terminar-pago' element={<TerminarCompraVendedor />} />
            <Route path='/smart-ticket' element={<SmartTicket />} />
            <Route path='/:landing' element={<LandingRecinto setNewUrlCartelera={setNewUrlCartelera} />} />
          </Routes>
        </div>
        <ErrorHandler />
        <CookiePolicy />
        <Footer />
      </ThemeProvider>
    </div>
  );
}
export default connect()(App);
